/** @format */

import React, { useEffect } from "react";
import Img from "./img";

const SellItemCard = ({ data }) => {
  const { imageUrl, price, name } = data;
  return (
    <div className="blog_share_box">
      <div className="bl_share_img">
        <Img src={imageUrl} alt={"a"} className="img-fluid" />
      </div>
      <div className="blog_share_details">
        <span className="comment_author"></span>
        <h5>{name}</h5>
        <p>{price}€</p>
      </div>
    </div>
  );
};

export default SellItemCard;
