/** @format */

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SellItemCard from "@/components/sell-item-card";
import { BlogTwoData } from "@/data";
import { SanityService } from "../sanity-api-service.js";

const BlogTwo = () => {
  const [airCon, setAirCon] = useState([]);
  useEffect(() => {
    fetchAirCondtions();
  }, []);

  const fetchAirCondtions = () => {
    SanityService.getAirConditionPricing()
      .then((sanityObject) => {
        console.log("sanityObject", sanityObject);
        setAirCon(sanityObject.airConditions);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const { posts } = BlogTwoData;
  return (
    <section className="blog_share_area section_padding blog-page">
      <Container>
        <Row>
          <p>***Navedene cene uključuju montažu***</p>
          {airCon.map((data, index) => (
            <Col lg={4} md={6} sm={12} key={`blog-one-${index}`}>
              <SellItemCard data={data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default BlogTwo;
